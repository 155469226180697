<template>
  <van-cell-group title="授权成功">
    <van-cell title="品牌编号" :value="brandCode" />
    <van-cell title="授权码" :value="authCode" />
  </van-cell-group>
</template>
<script>
import { CellGroup, Cell } from 'vant'
import { saveAuthInfoApi } from '@/api/wechat'
export default {
  components: { VanCellGroup: CellGroup, VanCell: Cell },
  data () {
    return {
      brandCode: '',
      authCode: ''
    }
  },
  created () {
    this.brandCode = this.$route.query.brandCode
    this.authCode = this.$route.query.auth_code
    this.saveAuthInfo()
  },
  methods: {
    saveAuthInfo () {
      if (!this.brandCode) {
        this.$toast('brandCode不能为空')
        return false
      }
      if (!this.authCode) {
        this.$toast('authCode不能为空')
        return false
      }
      saveAuthInfoApi(this.brandCode, this.authCode).then((res) => {
        if (res.status !== 0) {
          this.$toast('授权绑定失败，请重试')
          return false
        }
      })
    }
  }
}
</script>
