import { posHttp } from '@/utils/axios'

/**
 * 获取预授权码
 */
export const getPreAuthCodeApi = () =>
  posHttp.get(`/wechat/component/preauthcode`)

/**
 * 获取预授权码
 */
export const saveAuthInfoApi = (brandCode, authCode) =>
  posHttp.post(
    `/wechat/authorizer/auth/success?brandCode=${brandCode}&authCode=${authCode}`
  )

/**
 * 获取微信jssdk签名
 * @param {String } url 签名路径
 * @param {String } appId 公众号appId
 */
export const getWxSignatureApi = (url, appId = '') =>
  posHttp.get(`/wechat/authorizer/js/sign?url=${url}&appId=${appId}`)

/**
 * 生成小程序链接
 * @param {Object } data 参数
 */
export const generateWxProgramLinkApi = data =>
  posHttp.post('/wechat/program/url/link/generate', data)
